import { DayOfWork, QueryRange, Timestamps } from 'services/types'
import { ContactType } from 'domains/customer/screens/Contacts/types'

export enum PhoneType {
  'Comercial' = 'W',
  'Residential' = 'H',
  'CellPhone' = 'C',
}

export type Phone = {
  type: PhoneType
  countryCode: number
  provinceCode: number
  number: number
  extension?: number
  priorityOrder: number
}

export type PhoneResponse = Phone & {
  id: string
}

export type Account = {
  id?: string
  admin: boolean
}

export type Contact = {
  customerId: string
  name: string
  email: string
  note?: string
  phones?: Phone[]
  userId?: string
}

export type ContactResponse = Contact & {
  id: string
  phones: PhoneResponse[]
  createdAt: number
  updatedAt: number
}

export type ContactAccount = {
  id: string
  type: ContactType
}

export type PartitionContactOrder = {
  id?: string
  priorityOrder: number
}

export type ContactPartition = {
  id: string
  contacts: PartitionContactOrder[]
}

export interface UpdateContactRequest {
  name: string
  customerId: string
  phones: Phone[]
}

export type ContactQuery = {
  name?: string
  customerId?: string
  userId?: string
  email?: string
  countryCode?: number
  provinceCode?: number
  number?: number
  note?: string
  sort?: string
  sortType?: string
  partitionId?: string
  hasPhone?: boolean
} & Partial<QueryRange>

export type ContactAccountFragment = {
  id: string
  admin: boolean
}

export type ContactQueryResponse = {
  id: string
  phones?: PhoneResponse[]
  accounts?: ContactAccountFragment[] | null
  customerId: string
  name: string
  email: string
  note?: string
  userId?: string
  role?: string
} & Timestamps

export type ContactQueryResponseWithType = ContactQueryResponse & {
  type: ContactType
}

export type PartitionCanDeleteReponse = {
  id: string
  name: string
}

export type ContactCanDeleteReponse = {
  contactId: string
  name?: string
  partitions?: PartitionCanDeleteReponse[]
  hasControlDevice?: boolean
}

export const messageChannel = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
}

export type MessageChannel = keyof typeof messageChannel

export type ContactMessageConfiguration = {
  accountId: string
  messageChannels: MessageChannel[]
  startTime: string
  endTime: string
  days: DayOfWork[]
  eventGroupIds: string[]
}

export const reportType = {
  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
  ACCOUNT_EVENTS: 'ACCOUNT_EVENTS',
  SERVICE_ORDER_DETAIL: 'SERVICE_ORDER_DETAIL',
  SERVICE_ORDER_FORM: 'SERVICE_ORDER_FORM',
}

export type ReportType = keyof typeof reportType

export type ReportScheduleParameter = {
  eventGroupIds?: string[]
}

export const reportSchedulePeriodicity = {
  FIRST_DAY_OF_THE_MONTH: 'FIRST_DAY_OF_THE_MONTH',
  EVERY_SUNDAY: 'EVERY_SUNDAY',
  EVERY_MONDAY: 'EVERY_MONDAY',
  EVERY_TUESDAY: 'EVERY_TUESDAY',
  EVERY_WEDNESDAY: 'EVERY_WEDNESDAY',
  EVERY_THURSDAY: 'EVERY_THURSDAY',
  EVERY_FRIDAY: 'EVERY_FRIDAY',
  EVERY_SATURDAY: 'EVERY_SATURDAY',
}

export type ReportSchedulePeriodicity = keyof typeof reportSchedulePeriodicity

export type ReportScheduleResponse = {
  id: string
  createdAt: number
  updatedAt: number
  contactId: string
  accountId: string
  reportType: ReportType
  startReportTime?: number
  reportTime: string
  parameters: ReportScheduleParameter
  periodicity: ReportSchedulePeriodicity[]
}

export type GetContact = {
  id: string
  name: string
  role?: string
  email?: string
  phone?: {
    type: PhoneType
    number: number
    provinceCode: number
  }
  accounts: {
    id: string
    admin: boolean
    serviceType?: {
      name: string
      color: string
    }
    actionable?: boolean
    hasControl?: boolean
    aggregatedAccountName?: string
    isCentralUser?: boolean
  }[]
  messageConfigurations?: ContactMessageConfiguration[]
  reportScheduleConfigurations?: ReportScheduleResponse[]
  ubiAppUser: boolean
  hasPartitions: boolean
  allActionPlans: boolean
}

export type ReportScheduleRequest = {
  contactId?: string
  accountId: string
  reportType: ReportType
  startReportTime?: number
  periodicity: ReportSchedulePeriodicity[]
  reportTime: string
  parameters: ReportScheduleParameter
}

type ReportSchedulePayload = ReportScheduleRequest & {
  id?: string
}

export type ContactPayload = {
  customerId: string
  name: string
  role?: string
  email?: string
  contactId?: string
  phone?: {
    number: number
    type: PhoneType
    provinceCode: number
  }
  accounts: { id: string; admin: boolean }[]
  messageConfigurations?: ContactMessageConfiguration[]
  reportScheduleConfigurations?: ReportSchedulePayload[]
  ubiAppUser: boolean
  allPartitions: boolean
  allActionPlans: boolean
}
